<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Setting</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Setting</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Country</b></label>
                      <input
                        type="text"
                        v-model="payload.country"
                        class="form-control form-control-sm"
                        placeholder="Country"
                      />
                      <span class="error" v-if="error && error.country">{{
                        error.country
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Currency</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        :disabled="payload.created_on"
                        class="form-control form-control-sm"
                        placeholder="Currency"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Symbol</b></label>
                      <input
                        type="text"
                        v-model="payload.symbol"
                        class="form-control form-control-sm"
                        placeholder="Symbol"
                      />
                      <span class="error" v-if="error && error.symbol">{{
                        error.symbol
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Coin Price</b></label>
                      <input
                        type="text"
                        v-model="payload.coin_price"
                        class="form-control form-control-sm"
                        placeholder="Coin Price"
                      />
                      <span class="error" v-if="error && error.coin_price">{{
                        error.coin_price
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3 pad-top-25">
                    <div class="form-check">
                      <input type="checkbox" v-model="payload.is_default" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">Make as default</label>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.created_on"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Currency
                    </button>
                    <button
                      v-if="payload.created_on"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Currency
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Currencies</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Currency
                  </button>
                </div>
              </div>
              <div class="col-12 text-right">
                <span>Accounting Currency: INR</span>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>Country</th>
                  <th>Currency</th>
                  <th>SYMBOL</th>
                  <th>COIN PRICE</th>
                  <th>CONVERSION PRICE</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.country }}</td>
                      <td><sup class="text-danger fa fa-circle" style="font-size: 0.5em;" v-if="item.is_default"></sup> {{ item.id }}</td>
                      <td>{{ item.symbol }}</td>
                      <td>{{ item.coin_price }}</td>
                      <td>{{ item.conversion_price }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>Inactive</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        /></td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "region",
      defaultPayload: {
        id: null,
        country: null,
        coin_price: null,
        is_default: false,
        status: true
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.country === null ||
        that.payload.country === ""
      ) {
        this.error.country = "Country is required";
        proceed = false;
      }
      if (
        that.payload.symbol === null ||
        that.payload.symbol === ""
      ) {
        this.error.symbol = "Symbol is required";
        proceed = false;
      }
      if (
        that.payload.id === null ||
        that.payload.id === ""
      ) {
        this.error.id = "Currency is required";
        proceed = false;
      }
      if (that.payload.coin_price === null || that.payload.coin_price === "") {
        this.error.coin_price = "Coin Price is required";
        proceed = false;
      }
      console.log(this.error);
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>